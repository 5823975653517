import React from 'react';
import ContactMe from './contactme.js';
const Contact = () => {
    return (
        <div className="contactMe">
            <div className="contactMe-text">
                <div className="centerText2">
                    <ContactMe />
                </div>
            </div>
        </div>
    );
}

export default Contact;
