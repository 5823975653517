import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    faLinkedin, faReact,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { MdAttachMoney } from "react-icons/md";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="item1">
                </div>
                <div className="item2">
                    <span style={{ paddingRight: 5 }}>Copyright </span>
                    <FontAwesomeIcon icon={faCopyright} />{" "}
                    <span style={{ paddingLeft: 5 }}>
                        {new Date().getFullYear()} Zach Fauser. All Rights
                        Reserved.
                        &nbsp;
                        Made With React.js
                        &nbsp;
                        <Link to="/test">
                            <FontAwesomeIcon icon={faReact} />
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a href="/#/contactme" alt="Contact Me" className="click"><b>Contact Me</b></a>
                    </span>
                </div>
                <a
                    href="https://www.linkedin.com/in/zachfauser/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="item4"
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
