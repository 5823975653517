import React from 'react';
import logo from './logo.svg';
import { IoMail, IoLogoLinkedin, IoHome } from "react-icons/io5";
import './contact.css';
const ContactMe = () => {
  return (
    <div className="contactMe">
    <div className="contactMe-text">
    <div className="centerText2">
            <a class="pressableLogo" href='../' height="100" width="100" ><img src={logo} className="App-logo" alt="logo" height="100" width="100" style={{ height: 100, width: 100 }}/></a>
            <h1>Contact Me</h1>
            <p>If you need to reach me, please contact me via one of the methods below.</p>
            <a href="mailto:zach@zachfauser.com">
                <IoMail size={37}/>
            </a>
            <br></br>
            <br></br>
            <a href="https://www.linkedin.com/in/zachfauser/">
                <IoLogoLinkedin size={37}/>
            </a>
            <br></br>
            <br></br>
            <br></br>
            <p>Return Home</p>
            <a href='../'><IoHome size={37}/></a>
        </div>
    </div>
</div>
    );
}
export default ContactMe;