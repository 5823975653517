import React from 'react';
import './homeStyle.css';
const aboutMe = () => {
    return (
        <div id='aboutMe' className="aboutMe">
            <div class="centerText1">
                <h2>About Me</h2>
            </div>
            <div className="centerText2">
                <p>
                    Hello and welcome to my website&nbsp;
                        <a href="https://zachfauser.com/">zachfauser.com</a>!
                    I'm certified in&nbsp;
                        <a href="https://catalog-education.oracle.com/pls/certview/sharebadge?id=FF7CB77CCFB08BEA712E79A320C28712674BF2849845B19AA2BA95BF7DB4CC81">Oracle Cloud Infrastructure</a>
                    &nbsp;aswell as&nbsp;
                        <a href="https://www.credly.com/badges/070c92af-cada-4644-8474-90866793ecc1?source=linked_in_profile/">IBM Cloud.</a>
                    &nbsp;I am currently working on my Microsoft Azure AZ-104 certification.
                    I enjoy many different things in the IT space including things like the cloud and development.
                    I love learning new things in the IT space, and I'm always up for a challenge!
                    I have also created a browser and Visual Studio Code theme named "Zach's Blue Theme" which you can find <a href="https://zfauser.github.io/zachs-blue-theme/">here.</a>&nbsp;
                    I am currently working on LinkedIn skill assessments aswell and have earnt quite a few including Microsoft Azure, HTML, CSS, JavaScript, React, and Node. You can find all of my skill assessments on&nbsp;
                        <a href="https://www.linkedin.com/in/zachfauser/details/skills/">my LinkedIn profile.</a>
                </p>
            </div>
        </div>
    );
}
export default aboutMe;
