import React from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import Contact from './components/contactMe/contact.js';
import home from './components/home/home.js';
import Test from './test.js';

const Main = () => {
  return (
    <HashRouter>
          <Switch> {/* The Switch decides which component to show based on the current URL.*/}
            <Route exact path='/' component={home}></Route>
            <Route exact path='/test' component={Test}></Route>
            <Route exact path='/contactme' component={Contact}></Route>
          </Switch>
    </HashRouter>
  );
}

export default Main;