import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import oci from './oci.svg'
function Test() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={oci} className="App-logo" alt="OCI logo"/>
        <p>
          <code>
            This app was built with React.js and is running on Oracle Cloud Infrastructure!
          <br></br>
            Click below to go back home
          </code>
        </p>
        <Link to="/">
            <FaHome />
        </Link>
        <h4>This is a test page; it was built to try out react-router-dom and react-icons</h4>
      </header>
    </div>
  );
}

export default Test;
