import Welcome from "./welcome.js";
import AboutMe from "./aboutMe.js";
import Footer from "./Footer.js";
import { animateScroll } from "react-scroll";
import './homeStyle.css';
function scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "options-holder"
    });
};
function home() {
    return [
        <Welcome />,
        <AboutMe />,
        // execute scrollToBottom() function on click of arrow
        <div className="arrow" onClick={scrollToBottom}></div>,
        <Footer />
    ];
}
export default home;