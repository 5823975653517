import logo from "./logo.svg";
import Type from "./type.js";
import React from 'react';
import './homeStyle.css';
import './arrow.css';
const Welcome = () => {
    return (
        <div className="home">
            <header className="App-header">
                <div class="section">
                    <div class="top-padding1">
                        <img src={logo} className="App-logo" alt="logo" />
                    </div>
                    <h1>Hey! I'm Zach!</h1>
                    I'm <Type />
                </div>
            </header>
        </div>
    );
}
export default Welcome;
